import { LeadQueryResponse, PendingLeadQuery } from "../../domain";
import { isEqual } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  isErrorResponseMessage,
  isHandshakeResponseMessage,
  isLeadQueryResponseMessage,
  sendHandshake,
  ServerMessage,
  ServerPayload,
  toClientLeadQueryMessage,
  toLeadQueryResponse,
} from "./protocol";
import { dateTimeReviver } from "../../utils";
import { DateTime } from "luxon";
import { useCookies } from "react-cookie";

export type RemovePendingMessageFunc = (clientId: string) => void;
export type SendMessageFunc = (content: string) => void;
export type ResendMessageFunc = (message: PendingLeadQuery) => void;

export const useChat = () => {
  const [readyState, setReadyState] = useState<number | undefined>(undefined);
  const [messages, setMessages] = useState<Array<LeadQueryResponse>>([]);
  const [pendingMessages, setPendingMessages] = useState<
    Array<PendingLeadQuery>
  >([]);
  const [error, setError] = useState("");
  const [cookies, setCookie] = useCookies(["id"]);

  const webSocketUrl = process.env.REACT_APP_WEBSOCKET_API_URL || "";
  const sharedApiKey =
    process.env.REACT_APP_API_SHARED_KEY ||
    "kjsoafdjf8as8fj9qewpf9fwe9finoivisdsadfas";
  const ws = useRef<WebSocket | null>(null);

  useEffect(() => {
    if (!cookies.id) {
      setCookie("id", uuidv4(), { path: "/" });
    }
  }, [setCookie, cookies.id]);

  useEffect(() => {
    if (readyState === undefined || readyState === WebSocket.CLOSED) {
      console.log("creating new websocket");
      ws.current = new WebSocket(webSocketUrl);
      setReadyState(WebSocket.CONNECTING);
    }
  }, [webSocketUrl, readyState]);

  const removePendingMessage = useMemo<RemovePendingMessageFunc>(() => {
    return (clientId: string) => {
      const index = pendingMessages.findIndex((m) => m.clientId === clientId);
      if (index !== -1) {
        const before = pendingMessages.slice(0, index);
        const after = pendingMessages.slice(index + 1);

        setPendingMessages([...before, ...after]);
      }
    };
  }, [pendingMessages, setPendingMessages]);

  const sendMessage = useMemo<SendMessageFunc>(() => {
    return async (question: string) => {
      const message: PendingLeadQuery = {
        clientId: uuidv4(),
        question,
        sentAt: DateTime.utc(),
        retries: 0,
        failed: false,
        succeeded: false,
      };
      setPendingMessages([...pendingMessages, message]);
      ws.current?.send(
        JSON.stringify(toClientLeadQueryMessage(message, sharedApiKey))
      );
    };
  }, [ws, pendingMessages, setPendingMessages, sharedApiKey]);

  const resendMessage = async (message: PendingLeadQuery) => {
    for (const [index, pm] of pendingMessages.entries()) {
      if (isEqual(pm, message)) {
        setPendingMessages([
          ...pendingMessages.slice(0, index),
          {
            question: message.question,
            failed: false,
            retries: message.retries + 1,
            sentAt: DateTime.utc(),
            clientId: message.clientId,
            succeeded: false,
          },
          ...pendingMessages.slice(index + 1),
        ]);
        break;
      }
    }
    ws.current?.send(
      JSON.stringify(toClientLeadQueryMessage(message, sharedApiKey))
    );
  };

  if (ws.current) {
    ws.current.onopen = () => {
      setError("");
      setReadyState(ws.current?.readyState);
      if (ws.current && cookies.id) {
        sendHandshake(ws.current, sharedApiKey, cookies.id);
      }
    };

    ws.current.onmessage = (event) => {
      try {
        const message: ServerMessage<ServerPayload> = JSON.parse(
          event.data,
          dateTimeReviver
        );

        switch (message.payload.type) {
          case "LeadQueryResponse":
            if (isLeadQueryResponseMessage(message)) {
              setMessages([...messages, toLeadQueryResponse(message)]);
              // delay(() => {
              if (message.payload.question) {
                removePendingMessage(message.payload.question.clientId);
              }
              // }, 10);
            } else {
              console.error("Invalid ChatMessageNotification message", message);
            }
            break;
          case "HandshakeResponse":
            if (isHandshakeResponseMessage(message)) {
              setMessages(
                message.payload.messageThread.map(toLeadQueryResponse)
              );
              if (pendingMessages.length > 0) {
                pendingMessages.forEach((m) => {
                  if (!m.failed && !m.succeeded) {
                    ws.current?.send(
                      JSON.stringify(toClientLeadQueryMessage(m, sharedApiKey))
                    );
                  }
                });
              }
            }
            break;
          case "ErrorResponse":
            if (isErrorResponseMessage(message)) {
              if (message.payload.code !== 409) {
                // This error is caused when the simulator refreshes the UI to hotload changes and ends up
                // sending the handshake message more than once. We can ignore it as it should never happen
                // in production.
                setError(message.payload.error);
              }
            } else {
              console.error("Invalid ErrorResponse message", message);
            }
            break;
          default: {
            console.error("Unknown message type", message);
          }
        }
      } catch (e) {
        console.error("Failed to parse message", event.data, e);
      }
    };

    ws.current.onerror = () => {
      setError("connection error");
      setReadyState(ws.current?.readyState);
      ws.current?.close();
    };

    ws.current.onclose = (_) => {
      setReadyState(ws.current?.readyState);
      console.log("Websocket closed");
    };
  }

  return {
    messages,
    pendingMessages,
    sendMessage,
    error,
    removePendingMessage,
    resendMessage,
  };
};
