import React from "react";
import { ListItem, ListItemText, Theme } from "@mui/material";

type ChatBubbleProps = {
  message: string;
  isOwn: boolean;
};
export const ChatBubble: React.FunctionComponent<ChatBubbleProps> = ({
  isOwn,
  message,
}) => {
  return (
    <ListItem
      sx={
        isOwn
          ? [styles.chatLogItem, styles.chatLogOwnItem]
          : [styles.chatLogItem, styles.chatLogOtherItem]
      }
      color={isOwn ? "primary" : "secondary"}
    >
      <ListItemText sx={styles.chatLogItemContent}>
        <span style={{ fontSize: "12pt" }}>{message}</span>
      </ListItemText>
    </ListItem>
  );
};

const styles = {
  chatLogItem: {
    maxWidth: "75%",
    borderRadius: "2svh",
    padding: "5px 5px",
    marginTop: "5px",
    flexDirection: "column",
  },
  chatLogOtherItem: (theme: Theme) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    alignSelf: "flex-start",
    textAlign: "left",
    paddingLeft: 5,
    marginLeft: 2,
  }),
  chatLogOwnItem: (theme: Theme) => ({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    alignSelf: "flex-end",
    textAlign: "right",
    paddingRight: 5,
    marginRight: 2,
    flexDirection: "column",
  }),
  chatLogItemLabel: {
    marginTop: 0,
    marginBottom: 0,
    width: "100%",
    textAlign: "center",
  },
  chatLogItemLabelTxt: {
    fontWeight: "bold",
    fontSize: "8pt",
  },
  chatLogItemContent: {
    marginTop: 0,
    marginBottom: 0,
    width: "100%",
  },
  chatLogItemTxt: {
    fontSize: "12pt",
  },
};
