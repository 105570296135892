import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";

export const ChatAppBar: React.FunctionComponent = () => {
  return (
    <AppBar color="primary" position="sticky" sx={{ height: "8svh" }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={styles.title}>
          Talk To An Expert
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

const styles = {
  title: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  signOutBtn: {
    float: "right",
  },
};
