import { DateTime } from "luxon";
import { LeadQuery, LeadQueryResponse, PendingLeadQuery } from "../../domain";

export enum ClientMessageType {
  Handshake = "Handshake",
  LeadQuery = "LeadQuery",
}

export enum ServerMessageType {
  HandshakeResponse = "HandshakeResponse",
  LeadQueryResponse = "LeadQueryResponse",
  ErrorResponse = "ErrorResponse",
}

export interface ClientPayload {
  type: keyof typeof ClientMessageType;
}

export interface ServerPayload {
  type: keyof typeof ServerMessageType;
}

export interface ClientMessage<PAYLOAD extends ClientPayload> {
  id: string;
  token: string;
  payload: PAYLOAD;
}

export interface ServerMessage<PAYLOAD extends ServerPayload> {
  id: string;
  payload: PAYLOAD;
}

export interface ClientHandshakeMessage
  extends ClientMessage<{
    type: ClientMessageType.Handshake;
  }> {}

export const sendHandshake = (ws: WebSocket, token: string, id: string) => {
  const handshake: ClientHandshakeMessage = {
    id,
    token,
    payload: {
      type: ClientMessageType.Handshake,
    },
  };
  ws.send(JSON.stringify(handshake));
};

export interface HandshakeResponseMessage
  extends ServerMessage<{
    type: ServerMessageType.HandshakeResponse;
    messageThread: Array<ServerLeadQueryResponseMessage>;
    error?: string;
  }> {}

export const isHandshakeResponseMessage = (
  message: ServerMessage<ServerPayload>
): message is HandshakeResponseMessage => {
  return message.payload.type === ServerMessageType.HandshakeResponse;
};

export interface ClientLeadQuery
  extends ClientMessage<{
    type: ClientMessageType.LeadQuery;
    clientId: string;
    content: string;
    sentAt: DateTime;
  }> {}

export const toClientLeadQueryMessage = (
  pending: PendingLeadQuery,
  token: string
): ClientLeadQuery => {
  return {
    id: pending.clientId,
    token,
    payload: {
      type: ClientMessageType.LeadQuery,
      clientId: pending.clientId,
      content: pending.question,
      sentAt: DateTime.utc(),
    },
  };
};

export interface ServerLeadQueryResponseMessage
  extends ServerMessage<{
    type: ServerMessageType.LeadQueryResponse;
    question: LeadQuery;
    response: string;
  }> {}

export const isLeadQueryResponseMessage = (
  message: ServerMessage<ServerPayload>
): message is ServerLeadQueryResponseMessage => {
  return message.payload.type === ServerMessageType.LeadQueryResponse;
};

export const toLeadQueryResponse = (
  message: ServerLeadQueryResponseMessage
): LeadQueryResponse => {
  return {
    id: message.id,
    response: message.payload.response,
    question: message.payload.question,
  };
};

export interface ErrorResponseMessage
  extends ServerMessage<{
    type: ServerMessageType.ErrorResponse;
    code: number;
    error: string;
  }> {}

export const isErrorResponseMessage = (
  message: ServerMessage<ServerPayload>
): message is ErrorResponseMessage => {
  return message.payload.type === ServerMessageType.ErrorResponse;
};
