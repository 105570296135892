import { DateTime } from "luxon";

export const dateTimeReviver: (key: string, value: any) => any = (
  key,
  value
) => {
  if (typeof value === "string") {
    const dt = DateTime.fromISO(value);
    if (dt.isValid) {
      return dt;
    }
  }
  return value;
};

export function enumKeys<E extends object>(e: E): (keyof E)[] {
  return Object.keys(e) as (keyof E)[];
}
