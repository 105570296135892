import React from "react";
import "./App.css";
import { Chat } from "./features/chat/Chat";
import {
  Box,
  Container,
  createTheme,
  CssBaseline,
  Theme,
  ThemeProvider,
} from "@mui/material";
import { CookiesProvider } from "react-cookie";

const theme = createTheme({});

const App = () => {
  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App">
          <div className="App-header">
            <Box
              component="img"
              src="/orwellian-future-eye.png"
              alt="logo"
              sx={styles.logo}
            />
            <h1>Coming Soon</h1>
            <Container sx={styles.chatContainer}>
              <Chat />
            </Container>
          </div>
        </div>
      </ThemeProvider>
    </CookiesProvider>
  );
};

const styles = {
  chatContainer: (theme: Theme) => ({
    height: "100%",
    [theme.breakpoints.up("xs")]: {
      maxWidth: "95%",
      minWidth: "95%",
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "95%",
      minWidth: "95%",
    },
    [theme.breakpoints.up("md")]: {
      height: "75%",
      maxWidth: "50%",
      minWidth: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      height: "75%",
      maxWidth: "40%",
      minWidth: "40%",
    },
    [theme.breakpoints.up("xl")]: {
      height: "75%",
      maxWidth: "35%",
      minWidth: "35%",
    },
    position: "absolute",
    bottom: 0,
    right: 0,
    zIndex: 999,
    padding: 0,
    margin: 0,
  }),
  logo: (theme: Theme) => ({
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "60%",
    },
    [theme.breakpoints.up("md")]: {
      width: "60%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "60%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "60%",
    },
  }),
};

export default App;
