import { createContext } from "react";
import {
  RemovePendingMessageFunc,
  ResendMessageFunc,
  SendMessageFunc,
} from "./hooks";

export type ChatContextValue = {
  sendMessage: SendMessageFunc;
  removePendingMessage: RemovePendingMessageFunc;
  resendMessage: ResendMessageFunc;
};

export const ChatContext = createContext<ChatContextValue>({
  sendMessage: (_) => {
    throw new Error("default implementation");
  },
  removePendingMessage: (_) => {
    throw new Error("default implementation");
  },
  resendMessage: (_) => {
    throw new Error("default implementation");
  },
});
