import React, { useEffect, useMemo, useRef, useState } from "react";
import FlatList from "flatlist-react";
import { useChat } from "./hooks";
import { renderFunc } from "flatlist-react/lib/___subComponents/uiFunctions";
import { ChatContext } from "./context";
import { ChatInput } from "./ChatInput";
import { Container, Divider, List, Paper } from "@mui/material";
import { ChatBubble } from "./ChatBubble";
import { ChatAppBar } from "./ChatAppBar";
import DefaultLoadingIndicator from "flatlist-react/lib/___subComponents/DefaultLoadIndicator";

type ChatProps = {};

type MessageItem = { id: string; message: string; isOwn: boolean };
export const Chat: React.FunctionComponent<ChatProps> = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [disableAutoScroll, setDisableAutoScroll] = useState(false);
  const {
    messages,
    pendingMessages,
    removePendingMessage,
    sendMessage,
    resendMessage,
    error,
  } = useChat();
  const totalMessages = messages.length * 2 + pendingMessages.length;

  const renderItem: renderFunc<MessageItem> = useMemo(() => {
    return ({ id, message, isOwn }) => {
      return <ChatBubble message={message} key={id} isOwn={isOwn} />;
    };
  }, []);

  const messageItems = useMemo<Array<MessageItem>>(() => {
    const messageItems: Array<MessageItem> = [];
    messages.forEach((message) => {
      if (message.question) {
        messageItems.push({
          id: message.question.id,
          message: message.question.question,
          isOwn: true,
        });
      }
      messageItems.push({
        id: message.id,
        message: message.response,
        isOwn: false,
      });
    });
    messageItems.push(
      ...pendingMessages.map((message) => {
        return {
          id: message.clientId,
          message: message.question,
          isOwn: true,
        };
      })
    );
    return messageItems;
  }, [messages, pendingMessages]);

  useEffect(() => {
    if (!disableAutoScroll) {
      scrollRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollRef, totalMessages, disableAutoScroll]);

  const onScrollChatLog = useMemo(() => {
    return () => {
      const bottom = scrollRef?.current?.getBoundingClientRect()?.bottom;

      if (bottom && bottom <= window.innerHeight) {
        if (disableAutoScroll) {
          setDisableAutoScroll(false);
        }
      } else {
        if (!disableAutoScroll) {
          setDisableAutoScroll(true);
        }
      }
    };
  }, [scrollRef, disableAutoScroll, setDisableAutoScroll]);

  return (
    <ChatContext.Provider
      value={{
        sendMessage,
        resendMessage,
        removePendingMessage,
      }}
    >
      <Container sx={styles.chatContainer}>
        <ChatAppBar />
        <Paper sx={styles.chatPaper}>
          {/*{error && (*/}
          {/*  <div>*/}
          {/*    <span>{error}</span>*/}
          {/*  </div>*/}
          {/*)}*/}
          <List sx={styles.chatLog} onScroll={onScrollChatLog}>
            {!!messages && (
              <FlatList
                list={messageItems}
                renderItem={renderItem}
                renderWhenEmpty={() => <DefaultLoadingIndicator />}
              />
            )}
            <div ref={scrollRef} />
          </List>
          <Divider />
          <ChatInput />
        </Paper>
      </Container>
    </ChatContext.Provider>
  );
};

const styles = {
  chatContainer: {
    height: "100%",
    width: "100%",
  },
  chatLog: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minHeight: "60%",
    maxHeight: "60%",
    width: "100%",
    overflow: "scroll",
    listStyle: "none",
  },
  chatPaper: {
    width: "100%",
    height: "92%",
  },
};
